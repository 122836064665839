import algoliasearch from "algoliasearch"
import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import debounce from "lodash.debounce"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, InputGroup, Row } from "react-bootstrap"
import {
  Configure,
  connectStateResults,
  Hits,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-dom"
import SuperpodLogo from "../../assets/images/superpod/superpod-logo.png"
import CTA from "../../components/common/CTA"
import FormBuilder from "../../components/common/FormBuilder/Form-Builder"
import Frame from "../../components/common/frame"
import Links from "../../components/common/links"
import Navigation from "../../components/navigation"
import Featuredcontainer from "../../components/superpod/OnemillionContainer"
import Slide from "../../components/utility/slide_content"
import "../../styles/newsuperpod.scss"

function SuperPod(props) {
  const [searchWord, setSearchWord] = useState("")
  const inputRef = useRef("")
  const handleChange = debounce(value => {
    setSearchWord(value)
  }, 800)
  const onEnter = event => {
    if (event.key === "Enter") {
      setSearchWord(inputRef.current?.value)
    }
  }
  const onSubmit = () => {
    setSearchWord(inputRef.current?.value)
  }
  const [isMobile, setIsMobile] = useState()
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  const [isExpanded, setIsExpanded] = useState(false)
  const [expandedHeight, setExpandedHeight] = useState(
    isMobile ? "995px" : "650px"
  )
  const [selectedTags, setSelectedTags] = useState(["ALL"])
  const containerRef = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const toggleView = () => {
    const bytesElement = document.getElementById("1-bytes")
    if (isExpanded && bytesElement) {
      const pst = bytesElement.getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: pst - 180,
        behavior: "smooth",
      })
    }
    setIsExpanded(prev => !prev)
  }
  const handleTagClick = tag => {
    if (tag === "ALL") {
      setSelectedTags(["ALL"])
    } else {
      setSelectedTags(prev => {
        if (prev.includes("ALL")) {
          return [tag]
        }
        const newSelectedTags = prev.includes(tag)
          ? prev.filter(t => t !== tag)
          : [...prev, tag]

        return newSelectedTags.length === 0 ? ["ALL"] : newSelectedTags
      })
    }
  }
  useEffect(() => {
    if (isExpanded && containerRef.current) {
      const height = containerRef.current.scrollHeight
      setExpandedHeight(`${height}px`)
    }
  }, [isExpanded])

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentSuperPod1test {
            SuperOps {
              pages(where: { title: "SuperPodMain" }) {
                title
                newField {
                  ... on SuperOps_Card {
                    __typename
                    cardName
                    slug
                    title
                    subtext
                    image {
                      url
                    }
                  }
                }
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
              links(where: { section: "Superpodnew" }) {
                section
                icon {
                  url
                }
                slug
                linkName
              }
              episodes(orderBy: createdAt_DESC) {
                id
                season
                superpodPage
                audioDuration
                episodeNumber
                guestName
                tag
                coverImage {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
                image {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 540 } }
                    }
                  )
                }
                title
                slug
              }
              pageComponents(where: { pageName: "limitedEdition" }) {
                ctaBoxes {
                  primaryButtonText
                  type
                  theme
                  customBackgroundColor
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  isLinkVisible
                  listenLinkText
                  links {
                    section
                    icon {
                      url
                    }
                    slug
                    linkName
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.SuperOps.pages.map(page => {
              const { title, pageBlock, navigationBlock, seo, newField } = page
              const allEpisode = data.SuperOps.episodes
                .filter(episode => episode.superpodPage !== "bytes")
                .sort((a, b) => b.episodeNumber - a.episodeNumber)
              const featuredEpisode = allEpisode[0]
              const allEpisodeExceptLast = data.SuperOps.episodes
                .filter(episode => episode.superpodPage !== "bytes")
                .sort((a, b) => b.episodeNumber - a.episodeNumber)
              allEpisodeExceptLast.shift()
              const FilterEpisode = episode =>
                selectedTags.includes("ALL") ||
                selectedTags.length === 0 ||
                selectedTags.some(tag => episode.tag.includes(tag))

              const bytesEpisode = data.SuperOps.episodes
                .filter(episode => episode.superpodPage === "bytes")
                .sort((a, b) => b.episodeNumber - a.episodeNumber)

              const filteredEpisodes = data.SuperOps.episodes.filter(
                episode =>
                  selectedTags.length === 0 ||
                  episode.tag.some(t => selectedTags.includes(t))
              )
              const slug =
                featuredEpisode.superpodPage === "podcast"
                  ? "season1"
                  : featuredEpisode.superpodPage === "bytes"
                  ? "superpod-bytes"
                  : featuredEpisode.superpodPage === "limitedEdition"
                  ? "limited-edition"
                  : "season2"
              return (
                <div className="superpodmain">
                  <div className="pod-main mtm120 ">
                    <Frame seo={seo} footerClassName="Footer p-0">
                      <header>
                        <Navigation page="About us" newButton />
                      </header>

                      <section className="sp-hero">
                        <Container className="lyt-cntr">
                          <center>
                            <div className="sp-hero-content">
                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <img
                                  src={SuperpodLogo}
                                  className="superpod-logo"
                                  alt="logo"
                                />
                                <p className="sp-hero-subtext">
                                  {parse(pageBlock[0].content.text)}
                                </p>
                              </Slide>

                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <div className="line" />
                                <Slide style={{ "--sal-delay": "0.2s" }}>
                                  <div className="social-links-banner flex-wrap d-flex justify-content-center">
                                    <Links
                                      className="icons"
                                      items={data.SuperOps.links[0]}
                                      iconOnly
                                      name
                                    />
                                  </div>
                                </Slide>
                              </Slide>
                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <InputGroup className="flex-column">
                                  <FormBuilder
                                    formClassName="hero-form"
                                    errorClassName="error-message"
                                    inputClassName="email"
                                    inputErrorClassName="error-active"
                                    buttonText="SUBSCRIBE NOW"
                                    buttonClassName="primary-new"
                                    type="subscribe"
                                    path="subscription"
                                  />
                                  <p className="subscribe-text">
                                    {parse(pageBlock[1].content.text)}
                                  </p>
                                </InputGroup>
                              </Slide>
                            </div>
                          </center>
                        </Container>
                      </section>
                      <section className="sp-cta-video ">
                        <Slide delay="200">
                          <Container className="lyt-cntr">
                            <div className="ct-container">
                              <Row>
                                <Col md={4}>
                                  <p className="f-highlight">featured</p>
                                  <p className="left-title">
                                    {parse(pageBlock[8].content.text)}
                                  </p>
                                </Col>
                                <Col md={8} className=" featured-cta-box">
                                  {featuredEpisode && (
                                    <Featuredcontainer
                                      data={featuredEpisode}
                                      slug={slug}
                                      isFeatured
                                    />
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Container>
                        </Slide>
                      </section>

                      <Slide delay="200">
                        <section className="sp-cta-show prelative">
                          <Slide delay="200">
                            <div className="show-icon" w-100>
                              <img
                                src="https://media.graphassets.com/KTKJyAbjQ2KAJ7UJHSaD"
                                alt="icon-img"
                              />
                            </div>
                            <Container className="show-sel d-flex">
                              <Col lg={4} className="show-sel-left">
                                <div className="w-304">
                                  <div className="sel-s-l-t-1">
                                    <p>{parse(pageBlock[11].content.text)}</p>
                                    <div className="sel-s-l-input d-flex align-items-center br10">
                                      <input
                                        className="search__input w-100"
                                        type="text"
                                        placeholder="Search"
                                        ref={inputRef}
                                        onChange={e =>
                                          handleChange(e.target.value)
                                        }
                                        onKeyDown={e => onEnter(e)}
                                      />
                                      <div
                                        className="cursor-pointer search-icon"
                                        onClick={() => onSubmit()}
                                      >
                                        <img src="https://media.graphassets.com/H4IihxbMRiOwG3B5zJeA" />
                                      </div>
                                    </div>
                                    <div className="sel-s-l-tags">
                                      <p className="sel-s-l-tags-p">
                                        FILTER BY TAGS
                                      </p>
                                      <div
                                        className="d-flex col-gap-8 row-gap-18 flex-wrap"
                                        style={
                                          isMobile
                                            ? {
                                                height: expanded
                                                  ? "auto"
                                                  : "40px",
                                                overflow: "hidden",
                                              }
                                            : {
                                                height: "auto",
                                              }
                                        }
                                      >
                                        {[
                                          "ALL",
                                          "GROWTH",
                                          "LEADERSHIP",
                                          "TECHNOLOGY",
                                          "OPERATIONS",
                                          "SALES",
                                          "CYBERSECURITY",
                                          "MARKETING",
                                          "DIVERSITY",
                                          "M&A",
                                        ].map(tag => (
                                          <div
                                            key={tag}
                                            className={`sel-s-l-tag ${
                                              selectedTags.includes(tag)
                                                ? "selected"
                                                : ""
                                            } ${
                                              tag === "ALL" ? "all-tag" : ""
                                            }`}
                                            onClick={() => handleTagClick(tag)}
                                          >
                                            <p className="sel-s-l-tag-p">
                                              {tag}
                                            </p>
                                          </div>
                                        ))}
                                      </div>
                                      {isMobile && (
                                        <div className="view-all-btn-wrapper">
                                          <button
                                            className="view-all-btn"
                                            onClick={() =>
                                              setExpanded(!expanded)
                                            }
                                          >
                                            {expanded
                                              ? "View less tags  "
                                              : "view all tags  "}

                                            <img
                                              src="https://media.graphassets.com/C0ldpxRyGZTjqc4sHvlQ"
                                              alt="toggle icon"
                                              className={
                                                expanded ? "rotate" : ""
                                              }
                                            />
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                md={8}
                                className="show-sel-right"
                                style={
                                  isMobile
                                    ? {
                                        maxHeight: "900px",
                                      }
                                    : {
                                        maxHeight: "800px",
                                        height: "800px",
                                      }
                                }
                              >
                                {searchWord.length ? (
                                  <EpisodeSearch
                                    searchWord={searchWord}
                                    tags={selectedTags}
                                  />
                                ) : (
                                  allEpisodeExceptLast
                                    .filter(FilterEpisode)
                                    .map(episode => (
                                      <Featuredcontainer
                                        data={episode}
                                        slug={
                                          episode.superpodPage === "podcast"
                                            ? "season1"
                                            : episode.superpodPage ===
                                              "limitedEdition"
                                            ? "limited-edition"
                                            : "season2"
                                        }
                                        isFeatured
                                      />
                                    ))
                                )}
                              </Col>
                            </Container>
                            <div className="card-lngr"></div>
                          </Slide>
                        </section>
                      </Slide>

                      <Slide delay="200">
                        <section
                          ref={containerRef}
                          className={`sp-cta-bytes ${
                            isExpanded ? "expanded" : ""
                          } d-flex`}
                          style={{
                            height: isExpanded
                              ? expandedHeight
                              : isMobile
                              ? "995px"
                              : "650px",
                          }}
                          id="1-bytes"
                        >
                          <Slide delay="200">
                            <div className="bytes-card-text">
                              <div className="card-text-img w-100">
                                <img
                                  src="https://media.graphassets.com/LcNV5b2jSYycemkks66Y"
                                  alt="bytes-logo"
                                />
                              </div>
                              <div className="card-typo">
                                <p className="card-typo-para">
                                  {parse(pageBlock[12].content.text)}
                                </p>
                              </div>
                            </div>
                          </Slide>
                          {bytesEpisode.map(episode => (
                            <Slide delay="200">
                              <Link to={`/superpod-bytes/${episode.slug}`}>
                                <div
                                  key={episode.id}
                                  className="bytes-card-show"
                                  style={{
                                    backgroundImage: `url(${episode.image.url})`,
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <div className="center-image">
                                    <img
                                      src="https://media.graphassets.com/d7HRhD7QS7Wnq2J7gmMD"
                                      alt="play button"
                                    />
                                  </div>
                                  <div className="card-op"></div>
                                </div>
                              </Link>
                            </Slide>
                          ))}
                          <button
                            className="toggle-button"
                            onClick={toggleView}
                          >
                            {isExpanded ? "View Less " : "View all "}
                            <img
                              src="https://media.graphassets.com/C0ldpxRyGZTjqc4sHvlQ"
                              alt="toggle icon"
                              className={`toggle-icon ${
                                isExpanded ? "rotate" : ""
                              }`}
                            />
                          </button>
                          <div className="card-lngr"></div>
                        </section>
                      </Slide>

                      <section className="sp-links-cta">
                        <Slide delay="200">
                          <CTA
                            data={[data.SuperOps.pageComponents[0].ctaBoxes[1]]}
                            className="Layout-container"
                            lgRight={5}
                            lgLeft={5}
                            buttonText={
                              data.SuperOps.pageComponents[0].ctaBoxes[1]
                                .primaryButtonText
                            }
                            buttonClassName="primary-new color-black"
                          />
                        </Slide>
                      </section>
                    </Frame>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default SuperPod

const Hit = props => {
  return (
    <Featuredcontainer
      data={props.hit}
      slug={
        props.hit.superpodPage === "podcast"
          ? "season1"
          : props.hit.superpodPage === "limitedEdition"
          ? "limited-edition"
          : "season2"
      }
      isFeatured
    />
  )
}

const EpisodeSearch = React.memo(props => {
  const { searchWord, tags } = props

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_NEW_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_NEW_KEY
  )

  const Results = connectStateResults(
    ({ searchResults, children, searching }) => {
      return searchResults && searchResults.nbHits !== 0 ? (
        children
      ) : searching ? (
        <p className="font-white">searching</p>
      ) : (
        <p className="font-white">Not found</p>
      )
    }
  )

  let tagFilter = ""
  tags.map(item => {
    if (item !== "ALL") {
      if (tagFilter === "") tagFilter = `tag:"${item}"`
      else tagFilter += ` OR tag:"${item}"`
    }
  })

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="superpod_podcast"
      searchState={{
        query: searchWord,
      }}
      stalledSearchDelay={0}
    >
      {tags.map(item => {
        if (item !== "ALL") return <Configure filters={tagFilter} />
      })}
      <SearchBox className="ais-sb" />
      <Results>
        <Hits hitComponent={Hit} />
      </Results>
    </InstantSearch>
  )
})
